import { trpc } from "lib/trpc"
import { useMemo } from "react"
import { toast } from "react-toastify"

export function useCalendars() {
  const trpcUtils = trpc.useContext()
  const calendars = trpc.calendar.getCalendars.useQuery()
  const numberOfEventsThisMonthPerCalendar =
    trpc.calendar.getNumberOfEventsThisMonthPerCalendar.useQuery()
  const deleteCalendar = trpc.calendar.deleteCalendar.useMutation({
    onError: (err: any) => {
      console.log(err)
      alert("Hubo un error eliminando el calendario")
    },
    onSuccess: () => {
      toast.success("Calendario eliminado")
      trpcUtils.calendar.getCalendars.invalidate()
    },
  })

  const nativeCalendars = useMemo(() => {
    return calendars.data
      ?.filter((c) => c.source == "native")
      ?.sort((a, b) => a.summary.localeCompare(b.summary))
  }, [calendars.data])

  const mostRecentUpdatedCalendar = useMemo(() => {
    if (!calendars.data) return null

    return calendars.data?.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    })[0]
  }, [calendars.data])

  return {
    loading:
      calendars.isLoading || numberOfEventsThisMonthPerCalendar.isLoading,
    calendars: calendars.data,
    numberOfEventsThisMonthPerCalendar: numberOfEventsThisMonthPerCalendar.data,
    loadCalendars: () => null,
    deleteCalendar: async (calendarId: string) => {
      await deleteCalendar.mutateAsync(calendarId)
    },
    nativeCalendars,
    mostRecentUpdatedCalendar: mostRecentUpdatedCalendar,
  }
}

export function useGoogleCalendars() {
  const calendarsFromGoogle = trpc.calendar.getGoogleCalendars.useQuery()
  const googleAccounts = trpc.account.getGoogleAccounts.useQuery()

  return {
    calendarsFromGoogle: calendarsFromGoogle.data,
    loading: calendarsFromGoogle.isLoading || googleAccounts.isLoading,
    missingPermissions: calendarsFromGoogle.data === null,
    missingGoogleAccount: googleAccounts.data?.length === 0,
    primaryCalendar: calendarsFromGoogle?.data?.find((c) => c.primary) ?? null,
  }
}
